
  import { Component, Emit, Prop, Vue } from "vue-property-decorator";
  import { MyWorkActivityGroupData } from "@/components/my-work/activity-group.vue";

  @Component({
    components: {
      TaskRow: () => import("@/components/task/row.vue"),
    },
  })
  export default class MyWorkTaskGroup extends Vue {
    @Prop({ required: true }) readonly activity!: MyWorkActivity;
    @Prop({ required: true }) readonly myWorkActivityList!: MyWorkActivityList;
    @Prop({ required: true }) readonly myWorkTaskList!: MyWorkTaskList;
    @Prop({ required: true }) readonly group!: MyWorkActivityGroupData;
    @Prop({ required: true }) readonly user!: { first_name: string; last_name: string };
    @Prop({ required: true }) readonly checkedTasks!: number[];
    @Prop({ required: true }) readonly hoveringTasks!: number[];
    @Prop({ required: true }) readonly hoveringType!: string | null;
    @Prop({ required: true }) readonly bulkUpdating!: boolean;

    @Emit("task-check-changed")
    handleTaskCheckChanged(taskId: number, event: MouseEvent) {
      return { taskId, activityId: this.activity.id, type: this.group.type, event };
    }

    /**
     * Get all opened tasks for an activity. There is a distinction between the initial load and the search.
     *
     * @returns MyWorkTask[]
     */
    get openedTasksForActivity() {
      // when searching the activitylist also contains the tasks, so check this first
      const activity =
        this.myWorkActivityList && this.myWorkActivityList[this.group.type] != null ? this.myWorkActivityList[this.group.type]!.find((a) => a.id === this.activity.id) : null;
      if (activity && activity.tasks) {
        return activity.tasks;
      }

      if (!this.myWorkTaskList[this.group.type]) {
        return [];
      }

      return this.myWorkTaskList[this.group.type].filter((t) => t.activity_id === this.activity.id).sort((a, b) => (a.deadline < b.deadline ? -1 : 1));
    }
  }
